<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Four large oranges are squeezed to make six glasses of juice. How much juice can be made
        from six oranges?
      </p>
      <p class="mb-4">
        <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options1"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>

      <h3><u>Reason</u></h3>
      <p class="mb-2">
        <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options2"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'UPEIToTLItem1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: '7 glasses', value: 'a'},
        {text: '8 glasses', value: 'b'},
        {text: '9 glasses', value: 'c'},
        {text: '10 glasses', value: 'd'},
        {text: 'Other', value: 'e'},
      ],
      options2: [
        {
          text: 'The number of glasses compared to the number of oranges will always be in ratio 3 to 2.',
          value: 'one',
        },
        {text: 'With more oranges, the difference will be less.', value: 'two'},
        {
          text: 'The difference in the numbers will always be two.',
          value: 'three',
        },
        {
          text: 'With four oranges the difference was 2. With six oranges the difference would be two more.',
          value: 'four',
        },
        {
          text: 'There is no way of predicting.',
          value: 'five',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
